/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'time': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.022 7A5.022 5.022 0 111.977 7a5.022 5.022 0 0110.045 0zm1.2 0A6.222 6.222 0 11.777 7a6.222 6.222 0 0112.445 0zM7.5 4.277a.5.5 0 00-1 0v3.89a.5.5 0 00.5.5h2.722a.5.5 0 000-1H7.5v-3.39z" _fill="#B0B0B6"/>'
  }
})
